import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import AnimatedGoodToKnow from "./AnimatedGoodToKnow";

function AnimatedExplorationsDescriptions(props) {
    return (
        <>
            <div className="about-wrapper ptb--120 bg_color--1 text-center text-lg-left">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-xl-6 order-1 mt-4 order-lg-0 ">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/skrat-1.jpg"
                                    alt="S skratom po rudniku Sitarjevec"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-xl-6 order-0 order-lg-1">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">S škratom po Rudniku Sitarjevec</h2>
                                    <p className="description">
                                        V raziskovanje podzemnega sveta Izvoznega rova vas popelje nagajivi, vragolij poln
                                        sitarjevški škrat. Na igriv in hudomušen način boste izvedeli več o rudniku – kako izgledajo in
                                        kako težke so tukajšnje kamnine, kako se svetijo minerali, spoznali rudarsko orodje,
                                        zanimive zgodbe rudarjev ter prebivalce rudnika – netopirčke. Animirano vodenje je doživetje
                                        za celo družino.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AnimatedGoodToKnow/>

            <div className="about-wrapper ptb--120">
                <div className="container">
                    <div className="row row--35 align-items-center text-center text-lg-left">
                        <div className="col-lg-5 col-md-12 col-xl-6 order-1 mt-4 order-lg-0 ">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/skrat-4.jpg"
                                    alt="Vodeni ogledi v izvoznem rovu"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Pravljice in sitarjevške zgodbe</h2>
                                    <p className="description">
                                        V pravljičnem kotičku najmlajšim obiskovalcem ob obisku Izvoznega rova radi povemo tudi
                                        kakšno zgodbico. Nekaj teh je naša domačinka, pisateljica Darinka Kobal, zapisala v svoji
                                        knjigi »V temnih globinah Sitarjevca«.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-wrapper ptb--120">
                <div className="container">
                    <div className="row row--35 align-items-center text-center text-lg-left">
                        <div className="col-lg-6 col-md-12">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Litko in zgodba o Litku</h2>
                                    <p className="description">Litko je rudar in maskota Litijskega karnevala. Ta ima eno najdaljših tradicij v Sloveniji. Litko
                                        domuje v rudniku Sitarjevec in se skriva v njegovih globinah. Če boste imeli srečo, ga boste
                                        morda opazili v kakšnem stranskem rovu.</p>

                                    <p className="description">
                                        Pred davnimi časi se je v majhnem mestecu, med dvema hriboma, skozi katero teče reka
                                        Sava, vsako leto odvijal pustni karneval. Od začetka te lepe tradicije je več kot 170 let. Ljudje
                                        so se našemili v maškare, se sprehajali po mestu in se veselili. Med njimi je bil tudi žalostni,
                                        osamljeni rudar, ki se je želel našemiti v maškaro, vendar mu tega njegovi kolegi niso
                                        dovolili. Dneve in leta, si je želel, da bi bil najljubša maškara na karnevalu in da bi ga imeli vsi
                                        radi.
                                    </p>

                                    <div className="description">
                                        Nekega dne je na delu v rudniku sanjaril o karnevalski maski in zaspal. Ko se je
                                        naslednjič zbudil, je ves srečen opazil, da je oblečen v prav poseben kostum. Pokukal je iz
                                        rudnika in ugotovil, da se mesto spet pripravlja na karneval. Odločil se je, da pride v mesto –
                                        vesel in oblečen v kostum!
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/litko-pred-rudnikom-sitarjevec.jpeg"
                                    alt="Maskota Litko in litijskega postovanja pred Rudnikom Sitarjevec"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AnimatedExplorationsDescriptions;